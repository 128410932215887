import React from "react";
import { PageTitle } from "@/components/ui/typography";

export default function FormDivider({ text }: { text: string }) {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center">
        <span className="w-full border-t border-zinc-200 dark:border-zinc-800" />
      </div>

      <div className="relative flex justify-center text-xs uppercase">
        <span className="bg-zinc-50 px-2 dark:bg-zinc-950">{text}</span>
      </div>
    </div>
  );
}
