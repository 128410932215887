import * as React from "react";

import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";

const inputVariants = cva(
  "flex w-full rounded-md border bg-white text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium  focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-zinc-700 dark:bg-zinc-950 dark:text-zinc-200 dark:placeholder:text-zinc-400 dark:focus-visible:ring-zinc-500 dark:disabled:opacity-60",
  {
    defaultVariants: {
      variantSize: "md",
    },
    variants: {
      variantSize: {
        sm: "h-6 px-2 py-0.5 text-sm",
        md: "h-9 px-3 py-1 text-sm",
        lg: "h-11 px-4 py-1.5 text-base",
      },
    },
  },
);

const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> &
    VariantProps<typeof inputVariants>
>(({ className, variantSize, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(inputVariants({ variantSize }), className)}
      ref={ref}
      {...props}
    />
  );
});
Input.displayName = "Input";

export { Input };
