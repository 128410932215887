"use client";

import GoogleLogoIcon from "@/components/icons/GoogleIcon";
import { Button } from "@/components/ui/button";
import { track } from "@vercel/analytics/react";
import { signInWithRedirect } from "aws-amplify/auth";
import React from "react";
import { toast } from "sonner";

export default function SignupWithGoogle({
  mode,
}: {
  mode: "signup" | "signin";
}) {
  async function onGoogleSignup() {
    if (mode === "signup") {
      track("signup_submit", { provider: "google" });
    }

    try {
      await signInWithRedirect({
        provider: { custom: "google" },
      });
    } catch (err: any) {
      console.error("Error signing up with Google", err);

      let message = "";
      switch (err?.name) {
        case "UserAlreadyAuthenticatedException": {
          message = "User is already signed in";
          break;
        }
        default: {
          message = "Something went wrong. Try again later.";
        }
      }

      toast.error(message);
      console.error(mode, "error (Google)", err);
      throw err;
    }
  }

  return (
    <Button variant="outline" type="button" onClick={onGoogleSignup}>
      <GoogleLogoIcon className="mr-2 h-4 w-4" /> Google
    </Button>
  );
}
