"use client";

import { Button } from "@/components/ui/button";
import React from "react";
import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { toast } from "sonner";
import { signInWithRedirect } from "aws-amplify/auth";
import { track } from "@vercel/analytics/react";

export default function SignupWithGithub({
  mode,
}: {
  mode: "signup" | "signin";
}) {
  async function onGithubSignup() {
    if (mode === "signup") {
      track("signup_submit", { provider: "github" });
    }

    try {
      await signInWithRedirect({
        provider: { custom: "GitHub" },
      });
    } catch (err: any) {
      let message = "";
      switch (err?.name) {
        case "UserAlreadyAuthenticatedException": {
          message = "User is already signed in";
          break;
        }
        default: {
          message = "Something went wrong. Try again later.";
        }
      }

      toast.error(message);
      console.error(mode, "error (GitHub)", err);
      throw err;
    }
  }

  return (
    <Button variant="outline" type="button" onClick={onGithubSignup}>
      <GitHubLogoIcon className="mr-2 h-4 w-4" /> GitHub
    </Button>
  );
}
