"use client";
import * as React from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { signIn } from "aws-amplify/auth";
import { useRouter } from "next/navigation";
import FormDivider from "@/components/form-divider";
import SignupWithGithub from "@/app/(auth)/signup/with-github";
import SignupWithGoogle from "@/app/(auth)/signup/with-google";
import { toast } from "sonner";
import { z } from "zod";
import { useForm, useFormState } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

const schema = z.object({
  email: z
    .string()
    .email({ message: "Please enter a valid email address." })
    .min(1, { message: "Required." }),
  password: z.string().min(8, {
    message: "Password must be at least 8 characters.",
  }),
});

type schemaType = z.infer<typeof schema>;

export function LoginForm({ email }: { email?: string }) {
  const router = useRouter();

  async function onSubmit(values: schemaType) {
    try {
      await signIn({ username: values.email, password: values.password });
      router.push("/workspace/select");
    } catch (err: any) {
      let message;
      switch (err.name) {
        case "ZodError":
          message = err.issues[0].message;
          break;
        case "NotAuthorizedException":
          message = "Incorrect username or password.";
          break;
        case "UserAlreadyAuthenticatedException":
          message = "User is already signed in";
          break;
        case "UserNotConfirmedException":
          message = "User is not confirmed, please contact support.";
          break;
        default:
          message = "Something went wrong. Please contact support.";
          break;
      }

      console.error("Error signing in with email", err);
      toast.error(message);
      throw err;
    }
  }

  const form = useForm<schemaType>({
    resolver: zodResolver(schema),
    defaultValues: { email, password: "" },
  });

  return (
    <div className={"grid gap-6"}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-2">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} type="email" />
                </FormControl>
                <FormDescription />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input {...field} type="password" />
                </FormControl>
                <FormDescription />
                <FormMessage />
              </FormItem>
            )}
          />

          <FormSubmitButton />
        </form>
      </Form>

      <FormDivider text="OR" />

      <div className="flex flex-col gap-1">
        <SignupWithGithub mode="signin" />
        <SignupWithGoogle mode="signin" />
      </div>
    </div>
  );
}

const FormSubmitButton = () => {
  const state = useFormState();

  return (
    <Button
      type="submit"
      loading={state.isSubmitting || (state.isSubmitted && !state.isDirty)}
    >
      Login
    </Button>
  );
};
